import React from 'react'
import classNames from 'classnames'
import cs from '../../classnames.scss'
import s from './group-activity.scss'
import {GroupActivityProps} from './interfaces'

export const GroupActivity = ({ticketed, updatesCount, t}: GroupActivityProps) => (
  <div className={classNames(cs.evTextFont, cs.evTextColor)}>
    <div className={s.text}>{t(ticketed ? 'groups.ticketedExplanation' : 'groups.rsvpExplanation')}</div>
    {updatesCount > 0 ? (
      <div className={s.activity}>
        {updatesCount > 1 ? t('groups.activity.plural', {count: updatesCount}) : t('groups.activity.singular')}
      </div>
    ) : null}
  </div>
)
